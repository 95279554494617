<template>
  <div class="com-table" v-loading="loading">
    <el-table
      row-key="id"
      :data="data"
      @selection-change="handleSelectionChange"
      :header-row-style="headerRowStyle"
    >
      <el-table-column
        width="100"
        align="center"
        v-if="tableType"
        :type="tableType"
      >
      </el-table-column>
      <el-table-column
        v-for="(col, index) in rowHeader"
        :key="index"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        align="center"
      >
        <template slot-scope="scope">
          <ex-slot
            v-if="col.render"
            :render="col.render"
            :row="scope.row"
            :index="scope.$index"
            :column="col"
          >
          </ex-slot>
          <!-- 这里是循环我们的表头，会将表头值传入,会进行判断如果自己有进行自定义render的话，那么就采用render方式，否则渲染prop值 -->
          <span :title="scope.row[col.prop]" v-else>
            {{ scope.row[col.prop] ? scope.row[col.prop] : "/" }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="pageShow"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageObj.current"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageObj.total"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
// 自定义内容的组件
var exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null,
    },
  },
  render: (h, data) => {
    const params = {
      row: data.props.row,
      index: data.props.index + 1,
    };
    if (data.props.column) params.column = data.props.column;
    return data.props.render(h, params);
  },
};
export default {
  props: {
    // 表格类型
    tableType: String,
    // 表格数据
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 表头数据
    rowHeader: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: Boolean,
    tableStyle: Object,
    pageObj: Object,
    handleSizeChange: Function,
    handleCurrentChange: Function,
    pageShow: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    handleSelectionChange(val) {
      this.$emit("tableSelect", val);
    },
    headerRowStyle() {
      return {
        fontSize: "16px",
        color: '#333'
      };
    },
  },
  //自定义组件
  components: {
    "ex-slot": exSlot,
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-table {
  margin-bottom: 20px;
  border-top: 1px solid #ebeef5 !important;
  th,
  td {
    padding: 16px 0 !important;
    .cell {
      display: -webkit-box !important;
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical !important;
    }
  }
}
</style>

<style lang="scss" scoped></style>
